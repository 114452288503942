import { getCookie } from '@ui/helpers/getCookie'
import { useEffect, useState } from 'react'

export function useVersionCookie() {
  const [version, setVersion] = useState<string>('')
  useEffect(() => {
    setVersion(getCookie('_version_'))
  }, [])
  return version
}

export function useClearCartCookie() {
  const [cartCookie, setCartCookie] = useState<string>('')
  useEffect(() => {
    setCartCookie(getCookie('is_clear_cart'))
  }, [])
  return [cartCookie]
}

export function useAxonCookie() {
  const [axonCookie, setAxonCookie] = useState<string>('')
  useEffect(() => {
    setTimeout(() => {
      setAxonCookie(getCookie('_axwrt'))
    }, 1000)
  }, [])
  return [axonCookie]
}
