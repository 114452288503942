import { useGlobalState } from '@libs/client/hooks'
import {
  isPuchaseKey,
  localBuyNowOrderKey,
  localDiscountCodeKey,
  localIsBuyNowKey,
  localIsPayingKey,
  localOrderIdKey,
  localOrderKey,
  localPurchasedOrderKey,
  localShippingAddressKey,
  localSourceEventKey,
  localOtpOutKey,
  sessionIdKey,
  localStripePaymentConfirmKey,
  localIsCollectTax,
  localOrderPaypalId,
  localIsPaypalThankYouId,
  localVisitTimeKey,
  eventIdFbKey,
  lastPLabelItem,
  completeCard
} from '@libs/client/helpers'
import { useRouter } from 'next/router'
import { CustomerAddress } from '../services'

export const useLocalOrderId = () => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [orderId, setOrderId] = useGlobalState<string | null>(localOrderIdKey(slug))
  return [orderId, setOrderId] as const
}

export const useLocalOrder = <T extends SfTypes.Order>() => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [order, setOrder] = useGlobalState<T | null>(localOrderKey(slug))
  return [order, setOrder] as const
}

export const useLocalBuyNowOrder = <T extends SfTypes.Order>() => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [order, setOrder] = useGlobalState<T | null>(localBuyNowOrderKey(slug))
  return [order, setOrder] as const
}

export const useLocalPurchasedOrder = <T extends SfTypes.Order>() => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [order, setOrder] = useGlobalState<T | null>(localPurchasedOrderKey(slug))
  return [order, setOrder] as const
}

export const useLocalIsBuyNow = () => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [isBuyNow, setIsBuyNow] = useGlobalState<boolean | null>(localIsBuyNowKey(slug))
  return [isBuyNow, setIsBuyNow] as const
}

export const useLocalIsPaying = () => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [isPaying, setIsPaying] = useGlobalState<boolean | null>(localIsPayingKey(slug))
  return [isPaying, setIsPaying] as const
}

export const useLocalDiscountCode = () => {
  const [discountCode, setDiscountCode] = useGlobalState<string | null>(localDiscountCodeKey())
  return [discountCode, setDiscountCode] as const
}

export const useLocalShippingAddress = () => {
  const [localShipping, setLocalShipping] = useGlobalState<CustomerAddress | null>(
    localShippingAddressKey
  )
  return [localShipping, setLocalShipping] as const
}

export const useLastPLabelItem = () => {
  const [lastOrderItem, setLastOrderItem] = useGlobalState<string>(lastPLabelItem)
  return [lastOrderItem, setLastOrderItem] as const
}

export const useLocalSourceEvent = () => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [localSourceEvent, setLocalSourceEvent] = useGlobalState<SfTypes.SourceEvent | null>(
    localSourceEventKey(slug)
  )
  return [localSourceEvent, setLocalSourceEvent] as const
}

export const useLocalSessionId = () => {
  const [localSessionId, setLocalSissionId] = useGlobalState<string | null>(sessionIdKey)
  return [localSessionId, setLocalSissionId] as const
}
export const useLocalIsPuchase = () => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [localIsPuchase, setLocalIsPuchase] = useGlobalState<boolean | null>(isPuchaseKey(slug))
  return [localIsPuchase, setLocalIsPuchase] as const
}

export const useStripeConfirm = <T extends SfTypes.StripeConfirm>() => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [stripeConfirm, setStripeConfirm] = useGlobalState<T | null>(
    localStripePaymentConfirmKey(slug)
  )
  return [stripeConfirm, setStripeConfirm] as const
}
export const useOtpOut = () => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [otpOut, setOtpOut] = useGlobalState<boolean | null>(localOtpOutKey(slug))
  return [otpOut, setOtpOut] as const
}
export const useIsCollectTax = () => {
  const [isCollect, setIsCollect] = useGlobalState<boolean | null>(localIsCollectTax)
  return [isCollect, setIsCollect] as const
}
export const useIsCompletedCard = () => {
  const [isCompleteCard, setIsCompleteCard] = useGlobalState<boolean | null>(completeCard)
  return [isCompleteCard, setIsCompleteCard] as const
}
export const useLocalVisitTime = () => {
  const [localVisitTime, setLocalVisitTime] = useGlobalState<number | null>(localVisitTimeKey)
  return [localVisitTime, setLocalVisitTime] as const
}

export const useEventFbId = () => {
  const [eventId, setEventId] = useGlobalState<string | null>(eventIdFbKey)
  return [eventId, setEventId] as const
}

export const useLocalPalpalOrderId = () => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [localPalpalOrderId, setLocalPalpalOrderId] = useGlobalState<string | null>(
    localOrderPaypalId(slug)
  )
  return [localPalpalOrderId, setLocalPalpalOrderId] as const
}
export const useLocalIsPaypalThankyou = () => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [localIsPaypalThankYou, setLocalIsPaypalThankYou] = useGlobalState<boolean | null>(
    localIsPaypalThankYouId(slug)
  )
  return [localIsPaypalThankYou, setLocalIsPaypalThankYou] as const
}
