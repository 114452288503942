import 'assets/scss/index.scss'
import 'regenerator-runtime/runtime'
import 'core-js/stable'
import 'intersection-observer'
import 'swiper/swiper.scss'
import 'swiper/components/lazy/lazy.scss'

import App, { AppContext, AppProps } from 'next/app'
import {
  IS_SERVER_MOUNTED,
  useAnalytics,
  useGa,
  usePaygates,
  useSetting,
  useSettingDefault
} from '@ui/hooks'
import {
  getQueryString,
  initializeClientState,
  initializePreviewServerState,
  initializeServerState
} from '@ui/helpers'
import {
  gaExcludes,
  genSid,
  setGlobalState,
  tokenBrKey,
  useCheckoutService,
  useEventFbId,
  useGlobalState,
  useLocalSessionId,
  useLocalSourceEvent
} from '@libs/client'
import getConfig from 'next/config'

import DefaultLayout from '@ui/layouts/default'
import Error from './_error'
import MyHead from '@ui/components/next/MyHead'
import { OrderProvider } from '@ui/contexts'
import {
  FbpScript,
  GtagScript,
  initSentry,
  PinterestScript,
  SnapchatScript,
  TiktokPixelScript
} from '@ui/analytics'
import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { useGtag } from '@ui/hooks/useGtag'
import CountExpiredTime from '@ui/components/shared/CountExpiredTime'
import jwt from 'jsonwebtoken'
import { differenceInSeconds } from 'date-fns'
import PreviewExpiredPage from './previewexpired'
import { useState } from 'react'
import { NextPageContext, NEXT_DATA } from 'next/dist/shared/lib/utils'
import { parseCookie } from '@libs/server'
import { useAxon } from '@ui/hooks/useAxon'
import { AxonScript } from '@ui/analytics/axonScript'
// import { setCookie } from '@ui/helpers/getCookie'
// import Script from 'next/script'

declare global {
  interface Window {
    __NEXT_DATA__: NEXT_DATA
    initMap: () => void
  }
}

// initializeGlobalState()
if (process.browser) {
  initializeClientState()
}
// const isDev = process.env.NODE_ENV != 'production'
initSentry()
function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const analytics = useAnalytics()
  const [localSourceEvent, setLocalSourceEvent] = useLocalSourceEvent()
  const [eventFbId, setEventId] = useEventFbId()
  const [localSessionId, setLocalSissionId] = useLocalSessionId()
  const [sessionInit, setSissionInit] = useState(false)
  const [initEventId, setInitEventId] = useState(false)
  const fbpPixelIds = analytics?.pixel_ids ?? []
  const tiktokPixelIds = analytics?.tiktok_ids ?? []
  const snapchatPixelIds = analytics?.snapchat_ids ?? []
  const axonPixelIds = analytics?.axon_ids ?? []
  const settingDefault = useSettingDefault()
  const [storeSetting] = useSetting('store')

  const gaIdsAdmin = !gaExcludes.includes(storeSetting?.general?.domain || '')
    ? (settingDefault?.setting?.settings.general?.ga_ids ?? '').split(',')
    : []
  const gaIds = [...(storeSetting?.analytics?.google_analytic_ids ?? []), ...(gaIdsAdmin ?? [])]
  if (!localSessionId && !sessionInit) {
    setSissionInit(true)
    const initSession =
      router.query?.fbclid ||
      router.query?.gclid ||
      router.query?.wbraid ||
      router.query?.gbraid ||
      router.query?.aleid ||
      router.query?.epik ||
      router.query?.sclid ||
      router.query?.ttclid ||
      router.query?.i ||
      genSid()
    setLocalSissionId(initSession as string)
  }
  if (!eventFbId && !initEventId) {
    setInitEventId(true)
    setEventId(genSid())
  }
  const prevPath = useRef('')
  const { gtagTrackPageView } = useGtag()
  const { axonTrackPageView } = useAxon()
  const [, setToken] = useGlobalState<any>('token_detail')
  const preview_domain = getConfig().publicRuntimeConfig.previewdomains
  const tokent_key = getConfig().publicRuntimeConfig.tokenKey
  const paygate = usePaygates()?.creditcard
  const [tokenBr, setTokenBr] = useGlobalState<string>(tokenBrKey)
  const { getTokenBrainTree } = useCheckoutService()
  const checkTokent = () => {
    const token = router.query?.t?.toString()
    try {
      const decoded = jwt.verify(token, tokent_key)
      decoded.countdown_time = differenceInSeconds(new Date(decoded.exp * 1000), new Date())
      setToken(decoded)
    } catch (err: any) {
      console.error(err)
    }
  }
  useGa()
  const getTokenBr = async () => {
    try {
      if (paygate?.id) {
        const res = await getTokenBrainTree(paygate?.id)
        if (res) {
          setTokenBr(res)
        }
      }
    } catch (error) {
      getTokenBr()
    }
  }

  useEffect(() => {
    const onselless_sellable = (
      settingDefault?.setting?.settings.general?.onselless_sellable ?? ''
    ).split(',')
    if (
      window.location.hostname.endsWith(preview_domain) &&
      !onselless_sellable.includes(window.location.hostname)
    ) {
      checkTokent()
      const interval = setInterval(checkTokent, 30000)
      return () => clearInterval(interval)
    }
    if (paygate?.adapter === 'BRAINTREE' && !tokenBr) {
      getTokenBr()
    }
  }, [])

  useEffect(() => {
    if (pageProps?.statusCode != 401) {
      const ref = router.query.ref?.toString()
      setGlobalState({ [IS_SERVER_MOUNTED]: false })
      if (ref || (pageProps?.referer && !localSourceEvent)) {
        setLocalSourceEvent({
          referer: ref || pageProps?.referer,
          referer_data: getQueryString(router.query, ['subpath'])
        })
      }
    }
  }, [])

  useEffect(() => {
    const pagePath = router.pathname?.toString()
    if (pagePath !== prevPath.current) {
      const query = window?.location.search
      gtagTrackPageView(`${pagePath}${query}`)
      axonTrackPageView()
      prevPath.current = pagePath
    }
  }, [router])
  if (pageProps?.statusCode == 401) return <PreviewExpiredPage />
  if (pageProps?.statusCode >= 400) return <Error statusCode={pageProps.statusCode} />

  return (
    <>
      <MyHead />
      {/* TRACKING SCRIPT */}
      {!!snapchatPixelIds?.length && <SnapchatScript pixel_ids={snapchatPixelIds} />}
      <PinterestScript />
      {!!fbpPixelIds?.length && <FbpScript pixel_ids={fbpPixelIds} />}
      {!!tiktokPixelIds?.length && <TiktokPixelScript pixel_ids={tiktokPixelIds} />}
      {!!axonPixelIds?.length && <AxonScript axonIds={axonPixelIds} />}
      {!!gaIds?.length && <GtagScript gaIds={gaIds} />}
      {/* END TRACKING SCRIPT */}
      <DefaultLayout>
        <OrderProvider>
          <CountExpiredTime />
          <Component {...pageProps} />
        </OrderProvider>
      </DefaultLayout>
    </>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = App.getInitialProps && (await App.getInitialProps(appContext))
  const nextPageContext: NextPageContext = appContext.ctx
  const preview_domain = getConfig().publicRuntimeConfig.previewdomains
  const tokent_key = getConfig().publicRuntimeConfig.tokenKey

  let initState: { [key: string]: any } = {}
  if (!process.browser) {
    let statusCode: number
    const contextHost = appContext?.ctx?.req?.headers.host?.split(':')?.[0] ?? ''
    const host = (process.env.LOCAL_DOMAIN ?? contextHost) || ''

    const subpath = appContext.router.query.subpath as string
    const pathname = appContext.router.pathname
    const userCookie = parseCookie(appContext?.ctx.req?.headers?.cookie || '')?._version_
    const key = `pversion${subpath ? `_${subpath}` : ''}`
    const pVersionCookie = parseCookie(appContext?.ctx.req?.headers?.cookie || '')?.[key]
    const cookies = parseCookie(appContext?.ctx.req?.headers?.cookie || '')
    if (subpath !== 'sw.js') {
      if (host === process.env.PREVIEW_URL) {
        initState = await initializePreviewServerState(pathname, appContext.router.query)
      } else {
        initState = await initializeServerState(
          host,
          subpath,
          pathname,
          appContext.router.query,
          userCookie,
          pVersionCookie,
          cookies
        )
      }
    }
    setGlobalState({ [IS_SERVER_MOUNTED]: true })
    if (!(initState as any)?.store && appContext.ctx.res) {
      statusCode = 404
    } else if (!process.isAllDataLoaded) {
      statusCode = 503
    } else {
      statusCode = nextPageContext?.res?.statusCode || 200
    }
    if (appContext?.ctx?.res?.statusCode) {
      appContext.ctx.res.statusCode = statusCode
    }
    if (host !== process.env.PREVIEW_URL) {
      const onselless_sellable = (
        initState?.settingDefault?.setting?.settings?.general?.onselless_sellable ?? ''
      ).split(',')
      if (host.endsWith(preview_domain) && !onselless_sellable.includes(host)) {
        try {
          const token = appContext.router.query?.t?.toString()
          jwt.verify(token, tokent_key)
        } catch (err: any) {
          statusCode = 401
        }
      }
    }

    initState.statusCode = statusCode
  }
  const data = process.browser
    ? window?.__NEXT_DATA__?.props?.pageProps
    : {
        ...(initState.statusCode != 401 ? initState : { ['statusCode']: initState.statusCode }),
        [IS_SERVER_MOUNTED]: true
      }

  const eventID = nextPageContext?.req?.headers?.['x-request-id'] // unique id for deduplicating fbp events (fbp client and conversion fbp)
  const referer = nextPageContext?.req?.headers?.['referer']
  return {
    ...appProps,
    pageProps: {
      ...appProps?.pageProps,
      ...data,
      eventID,
      referer
    }
  }
}

export default MyApp
