import { getCookie } from 'src/helpers/getCookie'
import { OrderItem } from './../../../../src/types/interfaces/order.d'
import useAxios from '@ui/services/useAxios'
import getConfig from 'next/config'
import { useEventFbId, useLocalSessionId } from './useLocalCart'
import { isApplePayActive, versionCookie } from '@libs/client/helpers'
import { useRouter } from 'next/router'
import { useGlobalState, useRefDep } from '@libs/client/hooks'
import { useInactive } from '@ui/hooks'

const propertiesText = (properties?: S3Types.item_properties) =>
  Object.values(properties ?? {}).join(' / ')

const kongBasePath = getConfig().publicRuntimeConfig.kongBasePath

const userCookie = getCookie('_version_') || ''

export const useTrackingService = () => {
  const router = useRouter()
  const slug = router.query?.subpath?.toString()
  const slugRef = useRefDep(slug)
  const dataInactive = useInactive()
  const [localSessionId] = useLocalSessionId()
  const pCookie = slugRef.current
    ? getCookie(`pversion_${slugRef.current}`)
    : getCookie('pversion') || ''
  const [applePayActive] = useGlobalState(isApplePayActive)

  const [eventId] = useEventFbId()
  const { instance } = useAxios(kongBasePath, {
    version: `${versionCookie.includes(userCookie) ? userCookie : ''}`,
    pVersion: `${pCookie}`,
    pinactive: `${!!dataInactive?.pinactive}`,
    pvinactive: `${!!dataInactive?.pvinactive}`,
    'event-id': eventId
  })
  const location = (process.browser && encodeURIComponent(window.location.href)) || ''
  const width = (process.browser && window?.innerWidth) || ''
  const height = (process.browser && window?.innerHeight) || ''
  const trackingRemoveToCart = (selectedVariant?: OrderItem | null, quantity = 0) => {
    try {
      instance.post(
        `/c/r?i=${localSessionId}&v=${propertiesText(
          selectedVariant?.properties
        )}&n=${quantity}&l=${location}`
      )
    } catch (error) {
      console.error(`Add remove add to cart tracking`, error)
    }
  }
  const trackingAddToCart = (
    selectedVariant?: OrderItem | null,
    quantity = 0,
    isBuyNow = false
  ) => {
    try {
      instance.post(
        `/c/a?i=${localSessionId}&v=${propertiesText(
          selectedVariant?.properties
        )}&n=${quantity}&l=${location}&f=${isBuyNow ? 'checkout' : 'cart'}`
      )
      // instance.post(`/c?i=${localSessionId}&l=${location}`)
    } catch (error) {
      console.error(`Add to card tracking`, error)
    }
  }
  const trackingViewItem = () => {
    try {
      instance.post(`/v?i=${localSessionId}&l=${location}&w=${width}&h=${height}`)
    } catch (error) {
      console.error(`Add to view item tracking`, error)
    }
  }
  const trackingViewItemApplePay = (active: string) => {
    try {
      instance.post(`/v/ap?i=${localSessionId}&l=${location}&w=${width}&h=${height}&ap=${active}`)
    } catch (error) {
      console.error(`Add to view item tracking apple pay`, error)
    }
  }
  const trackingInitCheckout = () => {
    try {
      instance.post(`/ico?i=${localSessionId}&l=${location}`)
    } catch (error) {
      console.error(`Add to init checkout tracking`, error)
    }
  }
  const trackingInitCheckoutApplePay = (isBuyNow = false) => {
    try {
      instance.post(
        `/ic?i=${localSessionId}&l=${location}&ap=${applePayActive || ''}&f=${
          isBuyNow ? 'checkout' : 'cart'
        }`
      )
    } catch (error) {
      console.error(`Add to init checkout tracking`, error)
    }
  }

  const trackingViewSurvey = () => {
    try {
      instance.post(`/s/v?l=${location}`)
    } catch (error) {
      console.error(`Add to view item tracking`, error)
    }
  }
  const trackingSubmitSurvey = () => {
    try {
      instance.post(`/s/s?l=${location}`)
    } catch (error) {
      console.error(`Add to view item tracking`, error)
    }
  }
  const trackingExpiredSurvey = () => {
    try {
      instance.post(`/s/e?l=${location}`)
    } catch (error) {
      console.error(`Add to view item tracking`, error)
    }
  }
  const trackingAmazonCart = () => {
    try {
      instance.post(`/t/p/az/c?f=cart&l=${location}`)
    } catch (error) {
      console.error(`Add to view item tracking amazon cart`, error)
    }
  }
  const trackingAmazonCheckout = () => {
    try {
      instance.post(`/t/p/az/c?f=checkout&l=${location}`)
    } catch (error) {
      console.error(`Add to view item tracking amazon checkout`, error)
    }
  }

  const trackingApplePayCart = (selectedVariant?: OrderItem | null, quantity = 0) => {
    try {
      instance.post(
        `/c/a?i=${localSessionId}&v=${propertiesText(
          selectedVariant?.properties
        )}&n=${quantity}&l=${location}&f=cart&gw=apple_pay&ap=${applePayActive || ''}`
      )
    } catch (error) {
      console.error(`Add to apple pay card tracking`, error)
    }
  }

  return {
    trackingAddToCart,
    trackingRemoveToCart,
    trackingViewItem,
    trackingInitCheckout,
    trackingViewSurvey,
    trackingSubmitSurvey,
    trackingExpiredSurvey,
    trackingAmazonCart,
    trackingAmazonCheckout,
    trackingApplePayCart,
    trackingInitCheckoutApplePay,
    trackingViewItemApplePay
  }
}
