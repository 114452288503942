import { deepCloneObject } from './common'

export function buildCatalog(pages: S3Types.pages, isChanged = false): S3Types.catalog {
  const catalog: S3Types.catalog = {}
  pages &&
    Object.entries(pages)?.forEach(([subpath, page]) => {
      if (!page?.setting?.settings?.configurations?.show_inhomepage) return
      const sellpagePrices = sellpagePrice(page)
      const soldout = Object.entries(page.setting?.variants?.items ?? {}).every(
        ([, item]) => item.sellable === false
      )
      catalog[subpath] = {
        title: page.setting?.settings?.general?.title,
        review_score: page?.ratings?.ratio_rating ?? 4.9,
        price: sellpagePrices?.default_price ?? 0,
        compare_price: sellpagePrices?.compare_price ?? 0,
        logo: page.setting?.gallery?.[0] ?? '',
        fallback_logo: page.setting?.fallback_img?.[page.setting?.gallery?.[0] || ''],
        soldout,
        sort_number: page?.setting?.settings?.general?.sort_number ?? Number.MAX_SAFE_INTEGER,
        hide_clearance: !!page?.setting?.settings?.configurations?.hide_clearance
      }
    })
  if (isChanged) {
    const newEntriesSorting = Object.entries(catalog || [])
      ?.sort()
      ?.sort((a, b) => {
        return (
          (a[1]?.sort_number || Number.MAX_SAFE_INTEGER) -
          (b[1]?.sort_number || Number.MAX_SAFE_INTEGER)
        )
      })

    const newObjAfterSort: { [key: string]: any } = {}

    newEntriesSorting.forEach((item) => {
      newObjAfterSort[`${item[0]}`] = item[1]
    })

    return newObjAfterSort
  }
  return catalog
}

export function buildCollection(store: S3Types.store, versionCookie: string): S3Types.collection {
  let collection: S3Types.collection = {}
  // let pagesInHome: any = {}
  if (store.pages) {
    const pagesData = Object.values(store.pages).filter(
      (x) => x.setting?.settings?.configurations?.show_inhomepage
    )
    const listUnixProdLabel: Record<string, S3Types.pages> = Object.entries(pagesData).reduce(
      (acc, [subpath, page]) => {
        if (page.setting) {
          // SIH
          const productLabel =
            page.setting.settings.general?.title?.split('-')[0]?.toLowerCase()?.trim() || ''

          if ((acc as Record<string, S3Types.pages>)?.[productLabel]) return acc
          // find page label with condition sort number || publised at biggest
          const filterSameLabel = [...pagesData].filter((pageVal) => {
            return (
              pageVal?.setting?.settings.general?.title?.split('-')[0]?.toLowerCase()?.trim() ==
              productLabel
            )
          })
          const pageLabel = sortingPages(filterSameLabel)?.[0]
          return { ...acc, [productLabel]: { [subpath]: pageLabel } }
        }
        return acc
      },
      {}
    )
    const transformData: S3Types.pages = Object.values(listUnixProdLabel).reduce(
      (acc: S3Types.pages, next: S3Types.pages) => ({
        ...acc,
        ...next
      }),
      {}
    )
    collection = defineCollection(
      transformData as S3Types.pages,
      versionCookie,
      store.setting?.settings.general.domain
    )
  }
  return collection
}

export function defineCollection(
  uniquePrd: S3Types.pages,
  versionCookie: string,
  domain?: string
): S3Types.collection {
  let sortingUniquePage = sortingPages(Object.values(uniquePrd))
  let pagesPreOrder: S3Types.page[] = []
  if (versionCookie == 'vpo1') {
    const preOrderSlug =
      process._repository.default_store?.setting?.settings?.general?.pre_order?.[domain || '']

    pagesPreOrder = sortingUniquePage.filter((page) =>
      preOrderSlug?.includes(page?.setting?.settings?.general?.slug || '')
    )
    sortingUniquePage = sortingUniquePage.filter(
      (page) => !preOrderSlug?.includes(page?.setting?.settings?.general?.slug || '')
    )
  }

  const arrClearance = sortingUniquePage?.filter((page) => checkClearance(page))
  const arrPresentation = sortingUniquePage?.filter((page) => !checkClearance(page))
  const { newArrivals, bestsellers } = definePresentation(arrPresentation)
  return {
    ...(versionCookie == 'vpo1' && pagesPreOrder.length
      ? { 'pre-order': buildCatalog(convertArrToPages(pagesPreOrder)) }
      : {}),
    clearance: buildCatalog(convertArrToPages(arrClearance)),
    'new-arrivals': buildCatalog(convertArrToPages(newArrivals)),
    bestsellers: buildCatalog(convertArrToPages(bestsellers))
  }
}

// inputValue is an UNIQUE array which be SORTED
export function definePresentation(inputValue: S3Types.page[]) {
  if (!inputValue?.length) return {}
  const newArrivals: S3Types.page[] = []
  const bestsellers: S3Types.page[] = []
  inputValue?.forEach((item, index) => {
    index % 2 == 0 ? newArrivals?.push(item) : bestsellers?.push(item)
  })
  return { newArrivals, bestsellers }
  // }
}

const convertArrToPages = (arrSellpage?: S3Types.page[]): S3Types.pages => {
  if (!arrSellpage?.length) return {} as S3Types.pages // Explicitly cast the empty object to type pages
  return arrSellpage.reduce(
    (acc, next: S3Types.page) => ({
      ...acc,
      [next?.setting?.settings?.general.slug || '']: next
    }),
    {} as S3Types.pages // Explicitly cast the accumulator to type pages
  ) as S3Types.pages
}

const checkClearance = (page: S3Types.page) => {
  const sellpage = sellpagePrice(page)
  return (
    !page?.setting?.settings?.configurations?.hide_clearance &&
    (sellpage?.default_price ?? 0) / (sellpage?.compare_price ?? 1) <= 0.5
  )
}

export function sortingPages(pages: S3Types.page[]) {
  if (!pages?.length) return []
  const mqDataPage = process._repository._mq_active?.pages?.pages
  return pages?.sort((pageA: S3Types.page, pageB: S3Types.page) => {
    if (
      pageA.setting?.settings.general.sort_number == pageB.setting?.settings.general.sort_number
    ) {
      return (
        (mqDataPage?.[pageB?.setting?.settings.general.id || ''] || 0) -
        (mqDataPage?.[pageA?.setting?.settings.general.id || ''] || 0)
      )
    } else {
      return (
        ((pageB.setting?.settings.general.sort_number == 1
          ? Number.MAX_SAFE_INTEGER
          : pageB.setting?.settings.general.sort_number) || 0) -
        ((pageA.setting?.settings.general.sort_number == 1
          ? Number.MAX_SAFE_INTEGER
          : pageA.setting?.settings.general.sort_number) || 0)
      )
    }
  })
}

export function injectSellable(pageSetting?: S3Types.page_setting) {
  if (pageSetting?.variants?.items) {
    Object.keys(pageSetting.variants.items).map((page_variant_id) => {
      const variantItem = pageSetting.variants.items[page_variant_id]
      const productBaseSellable = !(
        process._repository.pbases?.[variantItem?.pbase_id ?? '']?.variants?.items?.[
          variantItem?.pbase_variant_id ?? ''
        ]?.sellable === false
      )

      const productPageSellable = !(
        process._repository.ppages?.[pageSetting?.settings?.general?.ppage_id ?? 'a']?.setting
          ?.variants?.items?.[variantItem?.ppage_variant_id ?? 'a']?.sellable === false
      )
      pageSetting.variants.items[page_variant_id].fulfillment_cost =
        process._repository.pbases?.[variantItem.pbase_id ?? '']?.variants?.items?.[
          variantItem?.pbase_variant_id ?? ''
        ]?.fulfillment_cost
      pageSetting.variants.items[page_variant_id].tariff_cost =
        process._repository.pbases?.[variantItem.pbase_id ?? '']?.variants?.items?.[
          variantItem?.pbase_variant_id ?? ''
        ]?.tariff_cost ?? 0
      pageSetting.variants.items[page_variant_id].product_cost =
        process._repository.pbases?.[variantItem?.pbase_id ?? '']?.variants?.items?.[
          variantItem?.pbase_variant_id ?? ''
        ]?.product_cost
      pageSetting.variants.items[page_variant_id].quantity_variant =
        process._repository.pbases?.[variantItem?.pbase_id ?? '']?.variants?.items?.[
          variantItem?.pbase_variant_id ?? ''
        ]?.quantity

      pageSetting.variants.items[page_variant_id].sellable =
        productBaseSellable && productPageSellable
    })
  }
}

export function injectSoldoutSellpage(store?: S3Types.store) {
  const repoStore = process._repository.stores?.[store?.setting?.settings?.general?.id ?? '']
  if (repoStore && store?.catalog) {
    Object.keys(store.catalog).forEach((page_slug) => {
      if (store && store.catalog) {
        const page = repoStore?.pages?.[page_slug]
        injectSellable(page?.setting)
        if (!page) store.catalog[page_slug] = { ...store.catalog[page_slug], soldout: true }
        else {
          const soldout = Object.entries(page.setting?.variants?.items ?? {}).every(
            ([, item]) => item.sellable === false
          )
          store.catalog[page_slug] = { ...store.catalog[page_slug], soldout }
        }
      }
    })
  }
}

/**
 * Remove properties which all related variant items are unsellable
 * @param variants
 */
export function removeUnavailableProperties(variants?: S3Types.page_variants) {
  if (variants && variants.items && variants.properties) {
    const sellableItems = Object.fromEntries(
      Object.entries(variants.items).filter((item) => item[1].sellable)
    )
    const availableProperties = Object.values(sellableItems).reduce((total, item) => {
      Object.entries(item.properties).forEach(([key, value]) => {
        if (!total[key]) total[key] = []
        if (!total[key].includes(value)) total[key].push(value)
      })
      return total
    }, {} as { [key: string]: string[] })
    const newProps = Object.entries(variants.properties).reduce((total, [key, prop]) => {
      const values = Object.fromEntries(
        Object.entries(prop.values ?? {}).filter(([itemKey]) => {
          return availableProperties[key] && availableProperties[key].includes(itemKey)
        })
      )
      if (!isEmpty(values)) total[key] = { ...prop, values }
      return total
    }, {} as S3Types.page_variants_properties)
    variants.properties = newProps
  }
}

/**
 * Remove properties which all related variant items are unsellable
 * @param variants
 */
export function removeUnavailablePropertiesUpsell(variants?: S3Types.page_variants) {
  if (variants && variants.items && variants.properties) {
    const sellableItems = Object.fromEntries(
      Object.entries(variants.items).filter(
        (item) => item[1].sellable && !((item[1]?.quantity_variant || 0) > 1)
      )
    )
    const availableProperties = Object.values(sellableItems).reduce((total, item) => {
      Object.entries(item.properties).forEach(([key, value]) => {
        if (!total[key]) total[key] = []
        if (!total[key].includes(value)) total[key].push(value)
      })
      return total
    }, {} as { [key: string]: string[] })
    const newProps = Object.entries(variants.properties).reduce((total, [key, prop]) => {
      const values = Object.fromEntries(
        Object.entries(prop.values ?? {}).filter(([itemKey]) => {
          return availableProperties[key] && availableProperties[key].includes(itemKey)
        })
      )
      if (!isEmpty(values)) total[key] = { ...prop, values }
      return total
    }, {} as S3Types.page_variants_properties)
    variants.properties = newProps
  }
}

/**
 * Remove properties which all related variant items are unsellable
 * @param variants
 */
export function removeBunderVariant(pageSetting?: S3Types.page_setting) {
  if (pageSetting?.variants?.items) {
    const variantItems = Object.fromEntries(
      Object.entries(pageSetting?.variants.items).filter(
        (item) => !((item[1]?.quantity_variant || 0) > 1)
      )
    )
    const gallaryTmp = Object.values(pageSetting?.variants.items)
      .filter((item) => !((item?.quantity_variant || 0) > 1))
      ?.map((x) => x?.logo)

    const gallaryTmpBundle = Array.from(
      new Set(
        Object.values(pageSetting?.variants.items)
          .filter((item) => (item?.quantity_variant || 0) > 1)
          ?.map((x) => x?.logo)
      )
    )

    const filterArr = pageSetting.gallery?.filter(
      (item) => !gallaryTmpBundle.includes(item) || gallaryTmp.includes(item)
    )

    pageSetting.variants.items = variantItems
    pageSetting.gallery = Array.from(new Set(filterArr))
  }
}

/**
 * Remove properties which all related variant items are unsellable
 * @param pageSetting
 * @param domainSellpage
 * @param version
 */
export function addUspPhoto(
  pageSetting?: S3Types.page_setting,
  domainSellpage?: string,
  version?: string
) {
  const uspConfig = process._repository.default_store?.setting?.settings?.general?.usp_config || {}
  if (pageSetting?.gallery && uspConfig?.[domainSellpage || '']) {
    if (uspConfig?.[domainSellpage || '']?.[version || '']) {
      pageSetting.gallery = deepCloneObject(pageSetting.gallery)
      pageSetting.gallery = [
        ...uspConfig?.[domainSellpage || '']?.[version || ''],
        ...pageSetting.gallery
      ]
    } else {
      console.warn(`Version ${version} on sellpage ${domainSellpage} is'nt configed on CMS admin`)
    }
  }
}

/**
 * Remove properties which all related variant items are unsellable
 * @param listReview
 * @param rating
 */
export function compileReviews(listReview?: S3Types.page_reviews, rating?: S3Types.page_rating) {
  const sellpageReviews = [...(listReview || []), ...(rating?.user_ratings || [])]
  const reviews = sellpageReviews?.slice(0, 3) || []
  const ratings = {
    ratio_rating: rating?.ratio_rating || 4.9,
    total_rating: sellpageReviews?.length || 0
  }
  return { reviews, ratings }
}

function initProperties(properties: S3Types.page_variants_properties) {
  const init: S3Types.item_properties = {}
  Object.keys(properties).forEach((key) => {
    init[key] = Object.entries(properties[key]?.values ?? {})?.[0]?.[1]?.code ?? ''
  })
  return init
}

function compareProperties(props1: S3Types.item_properties, props2: S3Types.item_properties) {
  let isEqual = true

  Object.keys(props1).forEach((key) => {
    if (props1[key] !== props2[key]) {
      isEqual = false
    }
  })

  return isEqual
}

function findVariant(
  properties: S3Types.item_properties,
  variantItems: S3Types.page_variants_items
) {
  const variantIds = Object.keys(variantItems)
  const foundVariantId = variantIds.find((variantId) =>
    compareProperties(properties, variantItems[variantId].properties ?? {})
  )
  return foundVariantId ? variantItems[foundVariantId] : undefined
}

function sellpagePrice(page: S3Types.page) {
  if (page) {
    const variants = page.setting?.variants
    if (variants) {
      // const defaultId = variants?.default_variant_id ?? ''
      // const item = variants.items?.[defaultId] ?? Object.values(variants.items)?.[0]
      const initProperty = initProperties(variants?.properties ?? {})
      const item = findVariant(initProperty, variants.items)
      return item
    }
  }
}

// function getAvarageRated(reviews: S3Types.page_review[]) {
//   if (reviews.length) {
//     const totalRated = reviews.reduce((total, review) => total + (review.rating ?? 0), 0)
//     return Math.ceil(totalRated / reviews.length)
//   }

//   return 0
// }

function isEmpty(obj: any) {
  return JSON.stringify(obj) === '{}'
}
