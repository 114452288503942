import { usePromotions, usePromotionsSellpage, useSetting, useSettingDefault } from '@ui/hooks'
import {
  isApplePayActive,
  isMaxItemNegative,
  isMaxQuantityDiscount,
  isMaxQuantityDiscountProduct,
  preCalcOrder,
  priceChangedVariantItemsKey
} from '@libs/client/helpers'
import { setGlobalState, useGlobalState, useRefDep } from '@libs/client/hooks'
import { useRouter } from 'next/router'
import {
  CartItem,
  CreatedCart,
  UpdatedCart,
  CartLegacyService,
  UpdatingCartItem,
  CustomerAddress,
  CustomerAddressField,
  BillingAddress,
  CollectTaxRequest
} from '../services'
import {
  useIsCollectTax,
  useLocalBuyNowOrder,
  useLocalDiscountCode,
  useLocalIsBuyNow,
  useLocalOrder,
  useLocalOrderId,
  useLocalPurchasedOrder,
  useLocalSessionId,
  useLocalSourceEvent
} from './useLocalCart'
import { useTrackingService } from './useTrackingService'
import { countItems } from '@ui/helpers'
import { method_type } from '@libs/common/models/constant'
import { convertNumber, trunc } from '@ui/helpers'

const getOrderItems = (items: SfTypes.Item[]): CartItem[] =>
  items.map((item) => ({
    id: item.id,
    quantity: item.quantity,
    variant_data: item.variant_data,
    variant_id: item.variant_id
  }))

export const useOrderService = () => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [localOrderId, setLocalOrderId] = useLocalOrderId()
  const [localOrder, setLocalOrder] = useLocalOrder()
  const [localBuyNowOrder, setLocalBuyNowOrder] = useLocalBuyNowOrder()
  const [purchasedOrder] = useLocalPurchasedOrder<Types.Order>()
  const [localIsBuyNow, setLocalIsBuyNow] = useLocalIsBuyNow()
  const localIsBuyNowRef = useRefDep(localIsBuyNow)
  const settingDefault = useSettingDefault()
  const localBuyNowOrderRef = useRefDep(localBuyNowOrder)
  const [localDiscountCode] = useLocalDiscountCode()
  const [localSourceEvent] = useLocalSourceEvent()
  const slugRef = useRefDep(slug)
  const localOrderIdRef = useRefDep(localOrderId)
  const localDiscountCodeRef = useRefDep(localDiscountCode)
  const localSourceEventRef = useRefDep(localSourceEvent)
  const [storeSetting] = useSetting('sellpage')
  const [localSessionId] = useLocalSessionId()
  const isSelless = router.query?.s || router.query?.selless
  const { trackingInitCheckout } = useTrackingService()
  const [, setIsCollect] = useIsCollectTax()
  const variantItems = storeSetting?.variants?.items ?? {}
  const promotions = usePromotions()
  const isThankyouAction = router?.query?.afs
  const [promotionsSellpage] = usePromotionsSellpage()
  const [discountCode] = useLocalDiscountCode()
  const [applePayActive] = useGlobalState(isApplePayActive)
  const applePayActiveRef = useRefDep(applePayActive)

  const afterSaleDcPercent =
    settingDefault?.setting?.settings.general?.after_sale_discount_percent ?? 0
  const afterSaleInitDcPercent =
    settingDefault?.setting?.settings.general?.after_sale_init_discount_percent ?? 0

  const discountPercentCode = discountCode || Object?.keys(promotionsSellpage || {})?.[0]

  const discountPercentUpsell = isThankyouAction
    ? storeSetting?.settings?.general?.upsell_info?.discount_percent ?? afterSaleDcPercent
    : storeSetting?.settings?.general?.upsell_info?.discount_percent || 0

  const initiateDiscountPercentUpsell = isThankyouAction
    ? storeSetting?.settings?.general?.upsell_info?.initiate_discount_percent ??
      afterSaleInitDcPercent
    : storeSetting?.settings?.general?.upsell_info?.initiate_discount_percent || 0

  const discountValuePercent: number =
    { ...promotions, ...promotionsSellpage }?.[discountPercentCode || '']?.type?.toLowerCase() ===
    'percent'
      ? { ...promotions, ...promotionsSellpage }?.[discountPercentCode || '']?.value || 0
      : 0

  const localOrderRef = useRefDep(localOrder)
  const [, setIsMaxQuantityDiscount] = useGlobalState<number>(isMaxQuantityDiscount)
  const [, setIsMaxQuantityDiscountProduct] = useGlobalState<number>(isMaxQuantityDiscountProduct)
  const [, setIsMaxItemNegative] = useGlobalState<boolean>(isMaxItemNegative)

  const lastCart = countItems(purchasedOrder?.items || []) ?? 0

  const updateLocalData = (
    result: CreatedCart & UpdatedCart,
    isBuyNow: boolean,
    currentItems: SfTypes.Item[]
  ) => {
    setLocalIsBuyNow(isBuyNow ?? false)
    if (result.id) setLocalOrderId(result.id)
    if (result.items) {
      const updatedItems: SfTypes.PriceChangedVariantItems = {}
      currentItems = currentItems.map((item) => {
        const price = result.items?.[item.id]
        if (price !== item.price && item.variant_id)
          updatedItems[item.variant_id] = { default_price: price }
        return {
          ...item,
          price: price ?? item.price
        }
      })
      setGlobalState({ [priceChangedVariantItemsKey(slugRef.current)]: updatedItems })
    }
    setLocalBuyNowOrder((prev: any) => ({
      ...prev,
      ...(result.id ? { id: result.id /*, code: result.code*/ } : {}),
      ...(isBuyNow ? { ...result, items: currentItems } : {})
    }))
    setLocalOrder((prev: any) => ({
      ...prev,
      ...(result.id ? { id: result.id /*, code: result.code*/ } : {}),
      ...(!isBuyNow ? { ...result, items: currentItems } : {})
    }))
  }

  const initItems = async (items: SfTypes.Item[], isBuyNow?: boolean, isStripe = false) => {
    const orderItems = getOrderItems(items)
    try {
      let service: Promise<CreatedCart & UpdatedCart>
      if (localOrderIdRef.current) {
        service = CartLegacyService.cart1({
          id: localOrderIdRef.current,
          f: isBuyNow ? 'checkout' : 'cart',
          l: encodeURIComponent(window.location.href) || '',
          gw: isBuyNow ? '' : isStripe ? 'STRIPE' : 'PAYPALEX',
          ap: (applePayActiveRef.current as string) || '',
          ...(isThankyouAction ? { lc: lastCart } : {}),
          body: {
            cartItems: orderItems,
            discount_code: localDiscountCodeRef.current || '',
            page_id:
              storeSetting?.settings?.general?.page_id || storeSetting?.settings?.general?.id || '',
            ...(storeSetting?.settings?.general?.version_id !== null &&
            storeSetting?.settings?.general?.version_id !== undefined
              ? { page_version: +(storeSetting?.settings?.general?.version_id || '0') }
              : {})
          }
        })
        if (isSelless) {
          trackingInitCheckout()
        }
      } else {
        service = CartLegacyService.cart({
          i: localSessionId || undefined,
          l: encodeURIComponent(window.location.href) || '',
          f: isBuyNow ? 'checkout' : 'cart',
          gw: isBuyNow ? '' : isStripe ? 'STRIPE' : 'PAYPALEX',
          ap: (applePayActiveRef.current as string) || '',
          ...(isThankyouAction ? { lc: lastCart } : {}),
          body: {
            items: orderItems,
            page_id:
              storeSetting?.settings?.general?.page_id || storeSetting?.settings?.general?.id || '',
            ...(localDiscountCodeRef.current
              ? { discount_code: localDiscountCodeRef.current }
              : {}),
            ...(slugRef.current ? { slug: slugRef.current } : {}),
            ...(localSourceEventRef.current
              ? {
                  source_referer: localSourceEventRef.current.referer,
                  source_referer_data: localSourceEventRef.current.referer_data
                }
              : {}),
            ...(storeSetting?.settings?.general?.version_id !== null &&
            storeSetting?.settings?.general?.version_id !== undefined
              ? { page_version: +(storeSetting?.settings?.general?.version_id || '0') }
              : {})
          }
        })
      }
      const result = await service
      updateLocalData(result, isBuyNow ?? false, items)
      return { ...result, items: items }
    } catch (err: any) {
      const errCode = err?.response?.data?.status
      if (errCode === 404) {
        const result = await CartLegacyService.cart({
          i: localSessionId || undefined,
          l: encodeURIComponent(window.location.href) || '',
          f: isBuyNow ? 'checkout' : 'cart',
          gw: isBuyNow ? '' : isStripe ? 'STRIPE' : 'PAYPALEX',
          ap: (applePayActiveRef.current as string) || '',
          ...(isThankyouAction ? { lc: lastCart } : {}),
          body: {
            items: orderItems,
            ...(localDiscountCodeRef.current
              ? { discount_code: localDiscountCodeRef.current }
              : {}),
            ...(slugRef.current ? { slug: slugRef.current } : {})
          }
        })
        updateLocalData(result, isBuyNow ?? false, items)
        return { ...result, items: items }
      } else {
        throw err
      }
    }
  }

  /**
   * Add new items to cart
   *
   * @param items items to add more to normal cart
   */
  const addItems = (items: SfTypes.Item[]) => {
    let currentItems = []
    if (storeSetting?.settings?.general?.upsell || isThankyouAction) {
      currentItems = calcOrder([...(localOrder?.items ?? []), ...items])
    } else {
      currentItems = [...(localOrder?.items ?? []), ...items]
    }
    const result = preCalcOrder(currentItems)
    setLocalIsBuyNow(false)
    setLocalOrder({
      ...localOrder,
      ...result,
      items: currentItems
    })
  }

  /**
   * Remove items in cart by item ids
   * @param itemIds
   */
  const removeItems = (itemIds: string[]) => {
    const temp = [...(localOrder?.items ?? [])]
    let items = []
    if (storeSetting?.settings?.general?.upsell || isThankyouAction) {
      items = calcOrder(temp.filter((item) => !itemIds.includes(item.id)))
    } else {
      items = temp.filter((item) => !itemIds.includes(item.id))
    }

    const result = preCalcOrder(items)
    setLocalOrder({
      ...localOrder,
      ...result,
      items
    })
  }

  /**
   * Update existed items with new quantity
   * @param items
   */
  const updateItems = (items: UpdatingCartItem[]) => {
    if (items.length > 0) {
      const temp = [...(localOrder?.items ?? [])]
      const tempItems = temp.map((item) => {
        const match = items.find((_item) => _item.id === item.id)
        return match ? { ...item, quantity: match.quantity } : item
      })
      let dataItems = []
      if (storeSetting?.settings?.general?.upsell || isThankyouAction) {
        dataItems = calcOrder(tempItems)
      } else {
        dataItems = tempItems
      }
      const result = preCalcOrder(dataItems)
      setLocalIsBuyNow(false)
      setLocalOrder({
        ...localOrder,
        ...result,
        items: dataItems
      })
    }
  }
  const calcOrder = (
    items: SfTypes.Item[],
    isGetItemsProductForm = false,
    lastItemDiscountProp = 0,
    itemIndexProp = 0
  ) => {
    let quantity = 0
    let isLastItemSameMax = false

    let lastItemDiscount = isThankyouAction ? lastCart - 1 : lastItemDiscountProp
    let itemIndex = isThankyouAction ? lastCart : itemIndexProp

    const itemsTemp = [...items].map((x) => {
      let totalAmount = 0
      let itemDefaultPrice = 0
      const quantityTmp = quantity + (x?.quantity || 0)
      const variantInfo = variantItems?.[x?.variant_id || '']
      const defaultPrice = variantInfo?.default_price || 0
      const minPrice =
        trunc(variantInfo?.fulfillment_cost || 0, 2) +
        trunc(variantInfo?.product_cost || 0, 2) +
        trunc(variantInfo?.tariff_cost || 0, 2) +
        2 +
        trunc((discountValuePercent * defaultPrice) / 100, 2)
      const maxItem = ~~(
        (defaultPrice - minPrice - (initiateDiscountPercentUpsell * defaultPrice) / 100) /
        ((defaultPrice * discountPercentUpsell) / 100)
      )
      setTimeout(() => {
        setIsMaxItemNegative(maxItem <= 0)
      }, 1)
      if (maxItem <= 0) {
        return {
          ...x
        }
      }
      if (lastItemDiscount > maxItem) {
        lastItemDiscount = maxItem
      }
      let itemDefaultPriceTmp = trunc(
        (defaultPrice *
          (100 - (initiateDiscountPercentUpsell + lastItemDiscount * discountPercentUpsell))) /
          100,
        2
      )
      for (let i = 0; i < (x?.quantity || 0); i++) {
        itemIndex++
        // next item
        if (itemIndex > 1) {
          // compare last_item_discount vs max_item_discount
          if (lastItemDiscount < maxItem) {
            lastItemDiscount++
            itemDefaultPriceTmp =
              defaultPrice *
              ((100 - (initiateDiscountPercentUpsell + lastItemDiscount * discountPercentUpsell)) /
                100)
          }
          itemDefaultPrice = trunc(itemDefaultPriceTmp, 2)
        } // first item
        else itemDefaultPrice = defaultPrice
        totalAmount = (totalAmount * 100 + itemDefaultPrice * 100) / 100
      }
      isLastItemSameMax = lastItemDiscount == maxItem
      const price = trunc(convertNumber(totalAmount, x?.quantity || 1), 2)
      quantity = quantityTmp
      return {
        ...x,
        price
      }
    })
    if (isGetItemsProductForm) {
      setIsMaxQuantityDiscountProduct(isLastItemSameMax ? lastItemDiscount : lastItemDiscount)
    } else {
      setIsMaxQuantityDiscount(isLastItemSameMax ? lastItemDiscount : lastItemDiscount + 1)
    }
    return itemsTemp
  }

  const updateShipping = <K extends keyof CustomerAddress>(
    field: CustomerAddressField,
    value: CustomerAddress[K]
  ) => {
    if (localOrderIdRef.current) {
      try {
        CartLegacyService.shipping({
          id: localOrderIdRef.current,
          body: {
            field,
            value
          }
        })
      } catch (err: any) {
        console.error(err)
      }
    }
  }
  const updateTaxAmount = async (
    address: CustomerAddress,
    method: method_type,
    isCancelPaypal = false,
    orderId?: string
  ) => {
    const body: CollectTaxRequest = {
      address1: address?.address1 || '',
      address2: address?.address2 || '',
      city: address?.city || '',
      country: address?.country || '',
      country_code: address?.country_code || '',
      postal_code: address?.postal_code || '',
      state: address?.state || '',
      method
    }
    const orderID = orderId || localOrderIdRef.current
    if (orderID) {
      try {
        if (method === method_type.CREDITCARD && !isCancelPaypal) {
          setIsCollect(true)
        }
        const res = await CartLegacyService.collectTax({
          f: localIsBuyNowRef.current ? 'checkout' : 'cart',
          id: orderID,
          body
        })
        updateLocalData(
          res,
          localIsBuyNowRef.current ?? false,
          (localIsBuyNowRef.current
            ? localBuyNowOrderRef?.current?.items
            : localOrderRef?.current?.items) as SfTypes.Item[]
        )
        return res
      } catch (err: any) {
        console.error('Error with update Tax', err)
        throw err
      }
    }
  }
  const updateOtpOut = (opt_out: boolean) => {
    if (localOrderIdRef.current) {
      try {
        CartLegacyService.optOut({
          id: localOrderIdRef.current,
          body: {
            opt_out
          }
        })
      } catch (err: any) {
        console.error(err)
      }
    }
  }

  const updateShippingAddress = async (address: CustomerAddress) => {
    if (localOrderIdRef.current) {
      try {
        const id = localOrderIdRef.current
        await Promise.all(
          Object.entries(address).map(async (field) => {
            const [key, value] = field as [CustomerAddressField, string]
            await CartLegacyService.shipping({
              id,
              body: {
                field: key,
                value
              }
            })
          })
        )
      } catch (err: any) {
        console.error(err)
        throw err
      }
    }
  }

  const updateBilling = async (orderId?: string, billing?: BillingAddress) => {
    if (orderId) {
      try {
        await CartLegacyService.billing({
          id: orderId,
          body: billing
        })
      } catch (err: any) {
        console.error(err)
        throw err
      }
    }
  }

  // Apple Pay
  const updateApplePayTaxAmount = async ({
    address,
    orderItems,
    orderId
  }: {
    address: CustomerAddress
    orderItems: CartItem[]
    orderId?: string | null
  }) => {
    if (orderId) {
      try {
        await CartLegacyService.cart1({
          id: orderId,
          f: localIsBuyNowRef.current ? 'checkout' : 'cart',
          l: encodeURIComponent(window.location.href) || '',
          gw: 'apple_pay',
          ap: (applePayActiveRef.current as string) || '',
          body: {
            cartItems: orderItems,
            page_id:
              storeSetting?.settings?.general?.page_id || storeSetting?.settings?.general?.id || '',
            ...(storeSetting?.settings?.general?.version_id !== null &&
            storeSetting?.settings?.general?.version_id !== undefined
              ? { page_version: +(storeSetting?.settings?.general?.version_id || '0') }
              : {})
          }
        })
        const taxRes = await updateTaxAmount(address, method_type.apple_pay, false, orderId)
        return taxRes
      } catch (err: any) {
        console.error('Error when update order with Apple Pay', err)
        throw err
      }
    } else {
      try {
        const initCart = await CartLegacyService.cart({
          i: localSessionId || undefined,
          l: encodeURIComponent(window.location.href) || '',
          f: localIsBuyNowRef.current ? 'checkout' : 'cart',
          gw: 'apple_pay',
          ap: (applePayActiveRef.current as string) || '',
          ...(isThankyouAction ? { lc: lastCart } : {}),
          body: {
            items: orderItems,
            page_id:
              storeSetting?.settings?.general?.page_id || storeSetting?.settings?.general?.id || '',
            ...(localDiscountCodeRef.current
              ? { discount_code: localDiscountCodeRef.current }
              : {}),
            ...(slugRef.current ? { slug: slugRef.current } : {}),
            ...(localSourceEventRef.current
              ? {
                  source_referer: localSourceEventRef.current.referer,
                  source_referer_data: localSourceEventRef.current.referer_data
                }
              : {}),
            ...(storeSetting?.settings?.general?.version_id !== null &&
            storeSetting?.settings?.general?.version_id !== undefined
              ? { page_version: +(storeSetting?.settings?.general?.version_id || '0') }
              : {})
          }
        })
        if (initCart.id) setLocalOrderId(initCart.id)
        const res = await updateTaxAmount(address, method_type.apple_pay, false, initCart.id)
        return res
      } catch (error) {
        console.error('Error when init order with Apple Pay', error)
      }
    }
  }

  return {
    initItems,
    addItems,
    removeItems,
    updateItems,
    updateShipping,
    updateShippingAddress,
    updateBilling,
    updateOtpOut,
    updateTaxAmount,
    calcOrder,
    updateApplePayTaxAmount
  }
}
